<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="container" [class.hidden]="borderLimit">
    <ion-title>{{ operation }}</ion-title>
    <ion-buttons slot="end">
      <ion-button clear (click)="close()">
        Cancel
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" scrollEvents="true" class="{{ authService.theme }}" (ionScroll)="logScrolling($event)">

  <div class="container">
    <form [formGroup]="form" *ngIf="form">

      <ion-grid>
        <ion-col >
          <ion-item class="input-wrapper" tappable (click)="openClient($event)" *ngIf="!model.request_uuid">
            <div tabindex="0"></div>
            <ion-label position="floating">Contact</ion-label>
            <ion-input type="text" formControlName="contact_name" readonly></ion-input>
          </ion-item>
    
          <ion-item class="input-wrapper" *ngIf="model.request_uuid">
            <div tabindex="0"></div>
            <ion-label position="floating">Contact</ion-label>
            <ion-input type="text" formControlName="contact_name" readonly></ion-input>
          </ion-item>
     
          <ion-radio-group formControlName="position_type">
            <ion-row>
              <ion-col>
                <ion-item class="toggle-wrapper">
                  <div tabindex="0"></div>
                  <ion-radio slot="start" value="1"></ion-radio>
                  <ion-label>Full time</ion-label>
                </ion-item>
              </ion-col>
    
              <ion-col>
                <ion-item class="toggle-wrapper">
                  <div tabindex="0"></div>
                  <ion-radio slot="start" value="2"></ion-radio>
                  <ion-label>Part time</ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-radio-group> 
    
          <ion-radio-group formControlName="gender">
            <ion-row>
              <ion-col>
                <ion-item class="toggle-wrapper">
                  <div tabindex="0"></div>
                  <ion-radio slot="start" value="1"></ion-radio>
                  <ion-label>Male</ion-label>
                </ion-item>
              </ion-col>
    
              <ion-col>
                <ion-item class="toggle-wrapper">
                  <div tabindex="0"></div>
                  <ion-radio slot="start" value="2"></ion-radio>
                  <ion-label>Female</ion-label>
                </ion-item>
              </ion-col>

              <ion-col>
                <ion-item class="toggle-wrapper">
                  <div tabindex="0"></div>
                  <ion-radio slot="start" value="3"></ion-radio>
                  <ion-label>Other</ion-label>
                </ion-item>
              </ion-col>
    
              <ion-col>
                <ion-item class="toggle-wrapper">
                  <div tabindex="0"></div>
                  <ion-radio slot="start" value="0"></ion-radio>
                  <ion-label>Any</ion-label>
                </ion-item>
              </ion-col>
              
            </ion-row>
          </ion-radio-group> 

          <app-select-search-input
            *ngIf="countrylistData"
            formControlName="nationality_id"
            placeholder="Select Nationality"
            title="Nationality"
            [collection]="countrylistData"
            valueAttr="country_id"
            labelAttr="country_nationality_name_en">
          </app-select-search-input>


          <ion-item class="input-wrapper">
            <div tabindex="0"></div>
            <ion-label position="floating">Position title</ion-label>
            <ion-input type="text" formControlName="position_title"></ion-input>
          </ion-item> 
           
            <ion-item *ngIf="!model.request_uuid" class="input-wrapper">
              <div tabindex="0"></div>
              <ion-label position="floating">Number of employees</ion-label>
              <ion-input type="text" formControlName="number_of_employees"></ion-input>
            </ion-item>
            
            <ion-item *ngIf="!model.request_uuid" class="input-wrapper">
              <div tabindex="0"></div>
              <ion-label position="floating">Number of employees per story</ion-label>
              <ion-input type="text" formControlName="no_of_employees_per_story"></ion-input>
            </ion-item>
      
            <ion-item class="input-wrapper">
              <div tabindex="0"></div>
              <ion-label position="floating">Location</ion-label>
              <ion-input type="text" formControlName="location"></ion-input>
            </ion-item> 
            
        </ion-col>

        <ion-col>
          <ion-item class="input-wrapper">
            <div tabindex="0"></div>
            <ion-label position="floating" class="mb-20">Job Description</ion-label>
    
            <ckeditor [config]="editorConfig" #ckeditor [editor]="Editor"
              (ready)="onEditorReady()" (change)="onChange($event)">
            </ckeditor>
    
          </ion-item> 
              
          <ion-item class="input-wrapper">
            <div tabindex="0"></div>
            <ion-label position="floating">Additional info</ion-label>
            <ion-textarea formControlName="additional_info" placeholder="Female only, Lebanese Jordanian Syrian NO EGYPTIAN"></ion-textarea>
          </ion-item> 
          
          <ion-item class="input-wrapper">
            <div tabindex="0"></div>
            <ion-label position="floating">Compensation</ion-label>
            <ion-textarea formControlName="compensation" placeholder="Please provide compensation detail"></ion-textarea>
          </ion-item> 

        </ion-col>
      </ion-grid>
      
      

      

        </form>
  </div>
</ion-content>
<ion-footer *ngIf="form" class="ion-no-border">
  <div class="container">
    <ion-button class="btn-save" [disabled]="!form.valid || saving" (click)="save()">
      <span *ngIf="!saving">Save</span>
      <ion-spinner *ngIf="saving"></ion-spinner>
    </ion-button>
  </div>
</ion-footer>
